import React from 'react'
import { FaGithub, FaLinkedin } from 'react-icons/fa'
import { HiOutlineMail } from "react-icons/hi"
import { BsFillPersonLinesFill} from "react-icons/bs"

const SocialLinks = () => {
    const links = [
        {
          id: 1,
          child: (
            <>
                LinkedIn <FaLinkedin size={30}/>
            </>
          ),
          href: 'https://linkedin.com/in/sumitakoliya',
          style: 'rounded-tr-md'
        },
        {
            id: 2,
            child: (
              <>
                  Github <FaGithub size={30}/>
              </>
            ),
            href: 'https://github.com/sammygojs',
            style: 'rounded-tr-md'
          },
          {
            id: 3,
            child: (
              <>
                  Email <HiOutlineMail size={30}/>
              </>
            ),
            href: 'mailto:skoliya.uk@gmail.com',
            style: 'rounded-tr-md'
          },
      ]


  return (
    <div className='flex flex-col top-[35%] left-0 fixed'>
        <ul>
                    {
                        links.map(({id, child, href, style, download})=>(
                        <li 
                            key={id} 
                            className={'flex justify-between items-center w-40 h-14 px-4 ml-[-100px] hover:ml-[-10px] rounded-2xl duration-300 bg-gray-500' + " " + style}>
                            <a 
                                href={href}
                                className='flex justify-between items-center w-full text-white'
                                target='_blank'
                                rel="noreferrer"
                            >
                                {child}
                            </a>
                        </li>

                    ))}
        </ul>
    </div>
  )
}

export default SocialLinks